import React from 'react';

import { P, Span, Div, Em, Code, Strong } from './Text.sx';

export default ({ element, children, className }) => {
  let Text;

  switch (element) {
    case 'p':
    default:
      Text = P;
      break;
    case 'span':
      Text = Span;
      break;
    case 'em':
      Text = Em;
      break;
    case 'code':
      Text = Code;
      break;
    case 'strong':
      Text = Strong;
      break;
    case 'div':
      Text = Div;
      break;
  }

  return <Text className={className}>{children}</Text>;
};
