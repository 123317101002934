import { useState, useEffect } from 'react';

export const useLastSectionTheme = () => {
  const [item, setItem] = useState();

  useEffect(() => {
    const items = document.querySelectorAll('section[data-theme-type]');
    setItem(items[items?.length - 1]?.dataset?.themeType);
  }, [item]);

  return item;
};
