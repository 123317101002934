import { sx } from '@lucifer/sx';

import ImageComponent from '~/components/Image';
import Section from '~/components/Section';

export const SectionImage = sx(Section)({
  display: 'flex',
  minHeight: 'auto',
});

export const Image = sx(ImageComponent)({
  margin: '0 auto',
  width: '100%',
  maxWidth: 2000,
  userSelect: 'none',
  userDrag: 'none',
});
