import React from 'react';
import { ThemeProvider } from 'emotion-theming';

import {dark} from '~/themes';

export default ({ themeName, children }) => {
  let theme;

  switch (themeName) {
    case 'dark':
    case 'none':
    default:
      theme = dark;
      break;
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
