import { sx } from '@lucifer/sx';

import Link from '~/components/Link';

export const Social = sx(Link)({
  lineHeight: 1,
  fontSize: 20,
  verticalAlign: 'middle',
  transition: 'color 0.2s ease-in-out',
  svg: {
    fill: 'currentColor',
  },
  color: 'bright',
  '&:hover': {
    color: 'bright',
  },
});
