import React from 'react';
import { getLinks } from '~/utils/sanity';
import SwitchTheme from '~/components/SwitchTheme';
import Link from '~/components/Link';

import {
  Wrap,
  SectionTextImage,
  ImageTextWrap,
  ImageTextTileImage,
  ImageTextTileText,
  ImageTextTileInner,
  ImageTextAsset,
  ImageTextContent,
} from './SectionTextImage.sx.js';

export default ({
  className,
  section,
  content,
  image,
  imageLink,
  reverseMobile,
  verticalAlign,
  reverse,
  themeName,
}) => {
  return (
    <SwitchTheme themeName={themeName}>
      <SectionTextImage className={className} section={section}>
        <Wrap wrapSize={section?.wrapSize}>
          <ImageTextWrap
            reverse={reverse}
            reverseMobile={reverseMobile}
            verticalAlign={verticalAlign}
          >
            <ImageTextTileText
              wrapSize={section?.wrapSize}
              reverse={reverse}
              reverseMobile={reverseMobile}
            >
              <ImageTextTileInner>
                <ImageTextContent content={content} reverse={reverse} />
              </ImageTextTileInner>
            </ImageTextTileText>
            <ImageTextTileImage>
              <ImageTextTileInner>
                <Link
                  to={getLinks(imageLink)?.to}
                  target={getLinks(imageLink)?.blank ? '_blank' : null}
                >
                  <ImageTextAsset image={image} />
                </Link>
              </ImageTextTileInner>
            </ImageTextTileImage>
          </ImageTextWrap>
        </Wrap>
      </SectionTextImage>
    </SwitchTheme>
  );
};
