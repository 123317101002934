import React from 'react';
// import { useTheme } from 'emotion-theming';

import { Logo, LogoImageText, Slate } from './Logo.styled';

export default ({ to = '/', type, className }) => {
  // const theme = useTheme();
  switch (type) {
    case 'image':
      return null;

    case 'text':
      return null;

    case 'imageText':
    default:
      return (
        <Logo ariaLabel="Logo" to={to} className={className}>
          <LogoImageText width="25" height="37.998" viewBox="0 0 25 37.998">
            <Slate
              d="M49.606,122.848V137.66l25-23.188V99.662Z"
              transform="translate(-49.606 -99.662)"
            />

            {/* <Slate d="M0 10v6.4l11.2-10V0L0 10z" /> */}
            {/* <path
              fill="currentColor"
              d="M13 13.3h3c2.7 0 4.4 1.6 4.4 4.3s-1.7 4.3-4.4 4.3h-3v-8.6zm3 6.8c1.5 0 2.3-.9 2.3-2.5s-.9-2.5-2.3-2.5h-.9v5h.9zM21.8 13.3h2.1v5.4c0 .9.6 1.4 1.5 1.4s1.5-.5 1.5-1.4v-5.4H29v5.4c0 1.9-1.4 3.3-3.6 3.3s-3.6-1.4-3.6-3.3v-5.4zM30.9 13.3H33l2.4 4c.3.6.7 1.5.7 1.5s-.1-.9-.1-1.5v-4h2.1v8.6H36l-2.4-4c-.3-.6-.7-1.5-.7-1.5s.1.9.1 1.5v4h-2.1v-8.6zM44.1 13.2c2.1 0 3.2 1.2 3.2 1.2l-1 1.6s-1-.9-2.1-.9c-1.6 0-2.4 1.2-2.4 2.4 0 1.3.8 2.6 2.4 2.6 1.3 0 2.3-1.1 2.3-1.1l1.1 1.5s-1.2 1.4-3.5 1.4c-2.7 0-4.5-1.9-4.5-4.4 0-2.4 1.9-4.3 4.5-4.3zM48.8 13.3h2.1v3.3h.9l1.8-3.3h2.3l-2.3 4.1 2.4 4.4h-2.4l-1.8-3.5h-.9v3.5h-2.1v-8.5zM57.1 13.3h5.4v1.8h-3.3v1.6h2.6v1.8h-2.6v1.6h3.4v1.8h-5.5v-8.6zM64.2 13.3h2.1v6.8h3.5v1.8h-5.6v-8.6zM70.9 13.3h5.3v1.8H73v1.8h2.6v1.8H73v3.2h-2.1v-8.6zM77.5 13.3h5.4v1.8h-3.3v1.6h2.6v1.8h-2.6v1.6H83v1.8h-5.5v-8.6zM84.6 13.3h2.1v6.8h3.5v1.8h-5.6v-8.6zM91.3 13.3h3c2.7 0 4.4 1.6 4.4 4.3s-1.7 4.3-4.4 4.3h-3v-8.6zm3 6.8c1.5 0 2.3-.9 2.3-2.5s-.9-2.5-2.3-2.5h-.9v5h.9z"
            /> */}
          </LogoImageText>
        </Logo>
      );
  }
};
