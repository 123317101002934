import React from 'react';
import Helmet from 'react-helmet';

import {
  getLinks,
  stripAfterChar,
  buildImageObj,
  imageUrlFor,
} from '~/utils/sanity';

export default ({ seo, socials, children }) => {
  // deconstruct seo object base paths
  const seoPage = seo?.page;
  const seoSite = seo?.site;
  const seoPageImage = seoPage?.figure;
  const seoSiteImage = seoSite?._rawFigure;

  // deconstruct seo object final meta
  const pageDescription = seoPage?.meta_description;
  const siteDescription = seoSite?.description;
  const pageLang = seoPage?.lang;
  const siteLang = seoSite?.lang;
  const pageMeta = seoPage?.meta;
  const pageKeywords = seoPage?.focus_keyword && [
    seoPage?.focus_keyword,
    ...(seoPage?.focus_synonyms ? seoPage?.focus_synonyms : []),
  ]; // patch together focus keyword and keyword synonyms from sanity seo-tool plugin and use them as meta keywords ~DH
  const siteKeywords = seoSite?.keywords;
  const pageTitle = seoPage?.seo_title;
  const siteTitle = seoSite?.title;
  const siteAuthor = seoSite?.author;
  const pageImage =
    seoPageImage &&
    imageUrlFor(buildImageObj(seoPageImage))
      .width(512)
      .height(512)
      .crop('center')
      .fit('max')
      .url();
  const pageImageType = seoPageImage?.asset?.mimeType;
  const pageImageWidth = seoPageImage?.asset?.metadata?.dimensions?.width;
  const siteImageWidth = seoSiteImage?.asset?.metadata?.dimensions?.width;
  const pageImageHeight = seoPageImage?.asset?.metadata?.dimensions?.height;
  const siteImageHeight = seoSiteImage?.asset?.metadata?.dimensions?.height;
  const siteImage =
    seoSiteImage &&
    imageUrlFor(buildImageObj(seoSiteImage))
      .width(512)
      .height(512)
      .crop('center')
      .fit('max')
      .url();
  const siteImageType = seoSiteImage?.asset?.mimeType;
  // const pageImageAlt = seoPageImage?.alt;
  // const siteImageAlt = seoSiteImage?.alt;

  const siteTwitter = socials
    ? socials?.socialItemType?.filter(
        (social) => social?.__typename === 'SanitySocialItemTwitter',
      )?.[0]?.socialItem?.username
    : null; // retrieve twitter username from companyInfo socials ~DH
  // const pageCanonical = ''; // if set the pageCanonical is the reference to the source page ~DH
  const siteCanonical = seoSite?.canonical; // in this case the siteCanonical is autogenerated via createPages.js slug the baseURL set as env variable ~DH
  const pageCanonicalRaw = getLinks(seoPage?.canonical)?.to; // retrieve canonical from seoPage meta ~DH
  const pageCanonical =
    pageCanonicalRaw && pageCanonicalRaw.charAt(0) === '/'
      ? `${process.env.GATSBY_BASE_URL_SITE}${pageCanonicalRaw}`
      : pageCanonicalRaw; // check if canonical is external or internal and add respective basePath ~DH
  const canonical = pageCanonical || siteCanonical;

  // use "page" related props first and "site" related props as fallback. ~DH
  const languages = pageLang || siteLang || 'de-DE'; // default fallback to german ~DH
  const description = pageDescription || siteDescription || ''; // use string as default that methods dont fail ~DH
  const keywords = pageKeywords || siteKeywords || []; // use array as default that methods dont fail ~DH
  const meta = pageMeta || []; // use array as default that methods dont fail ~DH
  const author = siteAuthor;
  const title =
    (pageTitle && siteTitle && `${siteTitle} | ${pageTitle}`) ||
    (pageTitle && pageTitle) ||
    (siteTitle && siteTitle);
  // const image = pageImage || siteImage;
  const image = pageImage || siteImage;
  const imageType = pageImageType || siteImageType;
  // const imageAlt = pageImageAlt || siteImageAlt;
  const imageWidth = pageImageWidth || siteImageWidth;
  const imageHeight = pageImageHeight || siteImageHeight;
  const twitter = siteTwitter;

  // transform e.g. "de-DE" to "de" or "['de-DE', 'en-EN]" to "de" ~DH
  const strippedPrimaryLanguage =
    stripAfterChar(languages?.[0]) || stripAfterChar(languages);

  const helmetMeta = [
    // og meta
    description && {
      name: 'description',
      content: description,
    },
    canonical && {
      name: 'og:url',
      content: canonical,
    },
    languages?.[0] && {
      name: 'og:locale',
      content: languages?.[0],
    },
    title &&
      description && {
        property: 'og:type',
        content: 'website',
      },
    title && {
      property: 'og:title',
      content: title,
    },
    siteTitle && {
      property: 'og:site_name',
      content: siteTitle,
    },
    description && {
      property: 'og:description',
      content: description,
    },
    image && {
      property: 'og:image',
      content: image,
    },
    image && {
      property: 'og:image:url',
      content: image,
    },
    image && {
      property: 'og:image:secure_url',
      content: image,
    },
    imageType && {
      property: 'og:image:type',
      content: imageType,
    },
    imageWidth && {
      property: 'og:image:width',
      content: imageWidth,
    },
    imageHeight && {
      property: 'og:image:height',
      content: imageHeight,
    },
    // imageAlt && {
    //   property: 'og:image:alt',
    //   content: imageAlt,
    // },
    // twitter summary cart
    author &&
      title && {
        name: 'twitter:card',
        content: 'summary',
      },
    author &&
      title && {
        name: 'twitter:creator',
        content: author,
      },
    author &&
      title && {
        name: 'twitter:site',
        content: twitter,
      },
    author &&
      title && {
        name: 'twitter:title',
        content: title,
      },
    author &&
      title &&
      description && {
        name: 'twitter:description',
        content: description,
      },
    author &&
      title &&
      image && {
        name: 'twitter:image',
        content: image,
      },
    // author &&
    //   title &&
    //   image &&
    //   imageAlt && {
    //     name: 'twitter:image:alt',
    //     content: imageAlt,
    //   },
  ]
    .concat(
      keywords && keywords.length > 0
        ? {
            name: 'keywords',
            content: keywords.join(', '),
          }
        : [],
    )
    // .concat(
    //   languages
    //     .filter((language, i) => i !== 0)
    //     .map((language) => ({
    //       name: 'og:locale:alternate',
    //       content: language,
    //     })),
    // )
    .concat(meta);

  return typeof helmetMeta[0] !== 'string' ? (
    <Helmet
      htmlAttributes={{ lang: strippedPrimaryLanguage }}
      title={pageTitle}
      titleTemplate={pageTitle === siteTitle ? '%s' : `${siteTitle} | %s`}
      link={[
        canonical && {
          rel: 'canonical',
          href: canonical,
        },
      ]}
      meta={helmetMeta}
    >
      <meta name="description" content={description} />
      {children}
    </Helmet>
  ) : null;
};
