import React from 'react';

import SwitchTheme from '~/components/SwitchTheme';
import { getLinks } from '~/utils/sanity';

import Link from '~/components/Link';
import Wrap from '~/components/Wrap';
import { SectionImage, Image } from './SectionImage.sx';

export default ({
  image,
  imageMobile,
  className,
  themeName,
  link,
  section,
}) => {
  return (
    <SwitchTheme themeName={themeName}>
      <SectionImage
        className={className}
        section={{
          ...section,
          paddingBottom: section?.paddingBottom || 'none',
          paddingTop: section?.paddingTop || 'none',
        }}
      >
        <Wrap wrapSize={section?.wrapSize}>
          <Link
            to={getLinks(link)?.to}
            target={getLinks(link)?.blank ? '_blank' : null}
          >
            <Image image={image} imageMobile={imageMobile} />
          </Link>
        </Wrap>
      </SectionImage>
    </SwitchTheme>
  );
};
