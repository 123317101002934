import { sx } from '@lucifer/sx';
import { media } from '~/utils/styles';

import Link from '~/components/Link';

export const LegalMenu = sx('nav')({
  color: 'bright',
  [media('tablet')]: {
    marginTop: 0,
  },
  '& > *': {
    marginRight: 40,
    '&:last-child': {
      marginRight: 0,
    },
  },
});

export const MenuItem = sx(Link)({
  transition: 'color 0.2s ease-in-out',
  color: 'greySenary',
  '&:hover': {
    color: 'bright',
  },
});
