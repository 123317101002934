import { sx } from '@lucifer/sx';
import { media } from '~/utils/styles';

export const H1 = sx('h1')({
  wordBreak: 'break-word',
  fontSize: 28,
  lineHeight: 1.4,
  letterSpacing: 0,
  fontWeight: 700,
  marginBottom: 24,
  fontFamily: 'heading',
  color: 'bright',

  [media('tablet')]: {
    fontSize: 36,
    letterSpacing: '-0.5px',
  },

  [media('laptop')]: {
    fontSize: 53,
    letterSpacing: '-0.5px',
  },
});

export const H2 = sx('h2')({
  wordBreak: 'break-word',
  fontSize: 22,
  lineHeight: 1.25,
  letterSpacing: 0,
  fontWeight: 700,
  fontFamily: 'heading',
  color: 'bright',

  [media('tablet')]: {
    fontSize: 30,
    letterSpacing: '-0.5px',
  },

  [media('laptop')]: {
    fontSize: 36,
    letterSpacing: '-0.5px',
  },
});

export const H3 = sx('h3')({
  wordBreak: 'break-word',
  fontSize: 15,
  lineHeight: 1.6,
  letterSpacing: 0,
  fontWeight: 700,
  marginBottom: 24,
  fontFamily: 'heading',
  color: 'bright',

  [media('tablet')]: {
    fontSize: 22,
    letterSpacing: '-0.5px',
  },

  [media('laptop')]: {
    fontSize: 24,
    letterSpacing: '-0.5px',
  },
});

export const H4 = sx('h4')({
  wordBreak: 'break-word',
  fontSize: 14,
  lineHeight: 1.7,
  letterSpacing: 0,
  fontWeight: 900,
  marginBottom: 24,
  textTransform: 'uppercase',
  fontFamily: 'heading',
  color: 'bright',
});

export const H5 = sx('h5')({
  wordBreak: 'break-word',
  fontSize: 12,
  lineHeight: 1.4,
  letterSpacing: 0,
  fontWeight: 900,
  marginBottom: 24,
  textTransform: 'uppercase',
  fontFamily: 'heading',
  color: 'bright',
});

export const H6 = sx('h6')({
  wordBreak: 'break-word',
  fontSize: 12,
  lineHeight: 1.4,
  letterSpacing: 0,
  fontWeight: 900,
  marginBottom: 24,
  fontFamily: 'heading',
  color: 'bright',
});
