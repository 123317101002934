import styled from '@emotion/styled';

import { media } from '~/utils/styles';

import LinkComponent from '~/components/Link';

const buttonStyles = {
  border: 0,
  userSelect: 'none',
  fontSize: 14,
  lineHeight: '24px',
  fontWeight: 600,
  paddingTop: 12,
  paddingRight: 24,
  paddingLeft: 24,
  paddingBottom: 12,
  display: 'inline-block',
  textDecoration: 'none',
  appearance: 'none',
  verticalAlign: 'bottom',
};

const buttonVariantSolidStyles = props => ({
  fontFamily: props.theme.fontPrimary,
  color: props.theme.buttonSolidColor,
  background: props.theme.buttonSolidBackground,
  transition: '0.2s background ease-in-out',
  marginTop: 20,
  '&:hover': {
    color: props.theme.buttonSolidColorHover,
    background: props.theme.buttonSolidBackgroundHover,
  },
  '&:active, &.active': {},
  '&:focus': {
    outlineColor: props.theme.buttonSolidOutlineColor,
  },
  opacity: props.disabled && 0.3,
  '&:disabled': {
    opacity: 0.3,
  },
});

const buttonVariantGhostStyles = props => ({
  fontFamily: props.theme.fontPrimary,
  color: props.theme.buttonGhostColor,
  background: 'transparent',
  border: `1px solid ${props.theme.buttonGhostBorderColor}`,
  paddingTop: 11,
  paddingRight: 23,
  paddingBottom: 11,
  paddingLeft: 23,
  transition: 'all 200ms ease-in-out',
  '&:hover': {
    color: props.theme.buttonGhostColorHover,
    borderColor: props.theme.buttonGhostBorderColorHover,
    backgroundColor: props.theme.buttonGhostBackgroundColorHover,
  },
  '&:active, &.active': {
    borderColor: props.theme.buttonGhostBorderColorActive,
  },
  '&:focus': {
    borderColor: props.theme.buttonGhostBorderColorFocus,
    outlineColor: props.theme.buttonGhostOutlineColor,
  },
  opacity: props.disabled && 0.3,
  '&:disabled': {
    opacity: 0.3,
  },
});

const buttonVariantStrippedStyles = props => ({
  fontFamily: props.theme.fontPrimary,
  color: props.theme.buttonStrippedColor,
  background: 'transparent',
  paddingRight: 12,
  paddingLeft: 12,
  [media('tablet')]: {
    paddingRight: 24,
    paddingLeft: 24,
  },
  '&:hover': {
    color: props.theme.buttonStrippedColorHover,
  },
  '&:focus': {
    outlineColor: props.theme.buttonStrippedOutlineColor,
  },
  opacity: props.disabled && 0.3,
  '&:disabled': {
    opacity: 0.3,
  },
});

const buttonVariantCircleStyles = props => ({
  color: '#ffffff',
  background: 'transparent',
  paddingRight: 80,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingTop: 0,
  position: 'relative',
  marginRight: 65,
  marginTop: 30,
  marginBottom: 40,
  whiteSpace: 'nowrap',

  [media('laptop')]: {
    marginTop: 40,
    marginBottom: 40,
  },

  '&:after': {
    content: '" "',
    borderRadius: '50%',
    border: '1px solid #fff',
    opacity: 0.1,
    position: 'absolute',
    right: 0,
    top: 0,
    width: 131,
    height: 131,
  },
  '&:hover': {
    color: props.theme.buttonStrippedColorHover,

    [ButtonIcon]: {
      transform: 'translateX(50px)',
      opacity: 0.5,
    },

    [ButtonMetaTitle]: {
      opacity: 1,
    },
  },
  '&:focus': {
    outlineColor: props.theme.buttonStrippedOutlineColor,
  },
  opacity: props.disabled && 0.3,
  '&:disabled': {
    opacity: 0.3,
  },
});

export const ButtonMetaTitle = styled('span')({
  display: 'block',
  fontSize: 12,
  lineHeight: '37px',
  opacity: 0.25,
  textTransform: 'uppercase',
  transition: 'all 500ms ease-out',
});

export const ButtonIcon = styled('span')({
  display: 'block',
  lineHeight: '37px',
  opacity: 0.25,
  textTransform: 'uppercase',
  marginLeft: 67,
  transition: 'all 200ms ease-out',
});

export const Input = styled('input')(
  { ...buttonStyles },
  props =>
    props.variant === 'solid' && {
      ...buttonVariantSolidStyles(props),
    },
  props =>
    props.variant === 'ghost' && {
      ...buttonVariantGhostStyles(props),
    },
  props =>
    props.variant === 'stripped' && {
      ...buttonVariantStrippedStyles(props),
    },
);

export const Link = styled(LinkComponent)(
  { ...buttonStyles },
  props =>
    props.variant === 'solid' && {
      ...buttonVariantSolidStyles(props),
    },
  props =>
    props.variant === 'ghost' && {
      ...buttonVariantGhostStyles(props),
    },
  props =>
    props.variant === 'stripped' && {
      ...buttonVariantStrippedStyles(props),
    },
  props =>
    props.variant === 'circle' && {
      ...buttonVariantCircleStyles(props),
    },
);

export const Button = styled('button')(
  { ...buttonStyles },
  props =>
    props.variant === 'solid' && {
      ...buttonVariantSolidStyles(props),
    },
  props =>
    props.variant === 'ghost' && {
      ...buttonVariantGhostStyles(props),
    },
  props =>
    props.variant === 'stripped' && {
      ...buttonVariantStrippedStyles(props),
    },
);
