import React from 'react';
import { Flex, Box } from 'theme-ui';
import { fractions } from '~/utils/sanity';
import SwitchTheme from '~/components/SwitchTheme';
import TilesModules from '~/components/TilesModules';

import {
  SectionColumn,
  Wrap,
  Content,
  Decoration,
  Grid,
} from './SectionColumn.sx';

export default ({ content, className, themeName, decoration, column }) => {
  // console.log(column?.columnsAlign);

  return (
    <SwitchTheme themeName={themeName}>
      <SectionColumn className={className}>
        <Decoration decoration={decoration} />
        <Wrap>
          <Grid>
            <Flex justifyContent="center">
              <Box
                sx={{ width: ['100%', null, null, null, `${(5 / 8) * 100}%`] }}
                pb={5}
              >
                <Content content={content} />
              </Box>
            </Flex>
            <Flex justifyContent={column?.columnsAlign}>
              <Box width={fractions(column?.columnsWidth)}>
                <TilesModules
                  columns={column?.columns}
                  columnWidth={column?.columnWidth}
                />
              </Box>
            </Flex>
          </Grid>
        </Wrap>
      </SectionColumn>
    </SwitchTheme>
  );
};
