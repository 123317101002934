import React from 'react';

export function PinLocation({ fillPrimary }) {
  return (
    <>
      <path
        fill={fillPrimary}
        d="M12.1 5C9.3 5 7 7.2 7 9.9c0 .9.3 1.8.7 2.6l4 6.3c.1.1.2.2.4.2s.3-.1.4-.2l3.9-6.4c1.4-2.3.6-5.4-1.8-6.7-.8-.4-1.6-.7-2.5-.7zm3.6 7l-3.6 5.7L8.4 12c-1.2-1.9-.6-4.5 1.4-5.7s4.6-.6 5.8 1.3c.9 1.4.9 3.1.1 4.4z"
      />
      <path
        fill={fillPrimary}
        d="M12.1 7.4c-1.4 0-2.6 1.2-2.6 2.5s1.2 2.4 2.6 2.4 2.5-1.1 2.5-2.4c0-1.3-1.1-2.5-2.5-2.5zm0 4.2c-.9 0-1.7-.7-1.7-1.6s.7-1.6 1.7-1.6 1.7.7 1.7 1.6c-.1.8-.8 1.6-1.7 1.6z"
      />
    </>
  );
}

export function SocialInstagram({ fillPrimary }) {
  return (
    <path
      fill={fillPrimary}
      d="M17.949,6a6.239,6.239,0,0,0-.4-2.063,4.346,4.346,0,0,0-2.486-2.486A6.239,6.239,0,0,0,13,1.051C12.1,1.01,11.808,1,9.5,1s-2.6.01-3.5.051a6.239,6.239,0,0,0-2.063.4,4.167,4.167,0,0,0-1.506.98,4.167,4.167,0,0,0-.98,1.506A6.239,6.239,0,0,0,1.051,6C1.01,6.9,1,7.192,1,9.5s.01,2.6.051,3.5a6.239,6.239,0,0,0,.4,2.063,4.346,4.346,0,0,0,2.486,2.486,6.239,6.239,0,0,0,2.063.4c.907.041,1.2.051,3.5.051s2.6-.01,3.5-.051a6.239,6.239,0,0,0,2.063-.4,4.346,4.346,0,0,0,2.486-2.486,6.239,6.239,0,0,0,.4-2.063c.041-.907.051-1.2.051-3.5S17.99,6.9,17.949,6Zm-1.53,6.939a4.7,4.7,0,0,1-.293,1.578,2.815,2.815,0,0,1-1.613,1.613,4.7,4.7,0,0,1-1.578.293c-.9.041-1.165.05-3.435.05s-2.539-.009-3.435-.05a4.7,4.7,0,0,1-1.578-.293,2.633,2.633,0,0,1-.977-.636,2.633,2.633,0,0,1-.636-.977,4.7,4.7,0,0,1-.293-1.578c-.041-.9-.05-1.165-.05-3.435s.009-2.538.05-3.435a4.7,4.7,0,0,1,.293-1.578A2.633,2.633,0,0,1,3.51,3.51a2.633,2.633,0,0,1,.977-.636,4.7,4.7,0,0,1,1.578-.293c.9-.041,1.165-.05,3.435-.05s2.538.009,3.435.05a4.7,4.7,0,0,1,1.578.293,2.633,2.633,0,0,1,.977.636,2.633,2.633,0,0,1,.636.977,4.7,4.7,0,0,1,.293,1.578c.041.9.05,1.165.05,3.435S16.46,12.038,16.419,12.935ZM9.5,5.135A4.365,4.365,0,1,0,13.865,9.5,4.365,4.365,0,0,0,9.5,5.135Zm0,7.2A2.833,2.833,0,1,1,12.333,9.5,2.833,2.833,0,0,1,9.5,12.333Zm5.557-7.371a1.02,1.02,0,1,1-1.02-1.02A1.02,1.02,0,0,1,15.057,4.963Z"
      transform="translate(-1 -1)"
    />
  );
}

export function SocialInstagramFilled({ fillPrimary }) {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9 7.10059C23.8 5.80078 23.6 5.00098 23.3 4.20117C23.1952 3.78223 23.0353 3.38965 22.8062 3.01074C22.5981 2.66699 22.333 2.33398 22 2.00098C21.3 1.30078 20.7 0.901367 19.9 0.600586C19.1 0.300781 18.3 0.100586 17 0.000976562H12C8.69995 0.000976562 8.30005 0.000976562 7.09998 0.100586C5.80005 0.100586 4.90002 0.300781 4.09998 0.600586C3.40002 0.901367 2.69995 1.30078 2 2.00098C1.62842 2.37305 1.34131 2.71582 1.10889 3.07617C0.903442 3.39453 0.740723 3.72559 0.599976 4.10059C0.300049 4.90039 0.0999756 5.80078 0.0999756 7.10059C0.0817871 7.31934 0.0668945 7.51172 0.0546875 7.7002C0.0311279 8.0625 0.0177002 8.41016 0.0101318 8.90332C0 9.55566 0 10.4639 0 12.001C0 15.3008 0 15.7002 0.0999756 16.9004C0.199951 18.2002 0.400024 19.001 0.699951 19.8008C0.900024 20.6006 1.30005 21.3008 2 22.001C2.69995 22.7002 3.30005 23.1006 4.09998 23.4004C4.90002 23.7002 5.69995 23.9004 7 24.001H12C13.6152 24.001 14.5356 24.001 15.1952 23.9893C15.8832 23.9775 16.2874 23.9521 16.9 23.9004C17.6245 23.8447 18.1937 23.7578 18.6942 23.6406C19.0918 23.5469 19.4459 23.4336 19.8 23.3008C19.9005 23.2637 19.9994 23.2236 20.0967 23.1826C20.1793 23.1475 20.2609 23.1113 20.3413 23.0732C20.9198 22.7988 21.4395 22.4375 21.9 21.9004C22.6 21.2002 23 20.6006 23.3 19.8008C23.6 19.001 23.8 18.2002 23.9 16.9004C24 15.7002 24 15.3008 24 12.001C24 8.70117 24 8.30078 23.9 7.10059ZM7.7998 11.999C7.7998 14.2949 9.70435 16.1992 11.9999 16.1992C14.2953 16.1992 16.1998 14.2949 16.1998 11.999C16.1998 9.7041 14.2953 7.79883 11.9999 7.79883C9.70435 7.79883 7.7998 9.7041 7.7998 11.999ZM5.7998 11.999C5.7998 15.3994 8.59985 18.2002 11.9999 18.2002C15.3998 18.2002 18.1998 15.3994 18.1998 11.999C18.1998 8.59863 15.3998 5.79883 11.9999 5.79883C8.59985 5.79883 5.7998 8.59863 5.7998 11.999ZM17 5.59961C17 6.40039 17.6 7.00098 18.4 7.00098C19.2001 7.00098 19.8 6.40039 19.8 5.59961C19.8 4.7998 19.2001 4.19922 18.4 4.19922C17.6 4.19922 17 4.7998 17 5.59961Z"
      fill={fillPrimary}
    />
  );
}

export function SocialLinkedin({ fillPrimary }) {
  return (
    <path
      d="M20.4 20.4H16.8V14.04C16.8 13.044 15.996 12.24 15 12.24C14.004 12.24 13.2 13.044 13.2 14.04V20.4H9.6V9.6H13.2V11.04C13.8 10.032 15.108 9.36 16.2 9.36C18.516 9.36 20.4 11.244 20.4 13.56V20.4ZM5.4 7.572C4.2 7.572 3.228 6.6 3.228 5.4C3.228 4.2 4.2 3.228 5.4 3.228C6.6 3.228 7.572 4.2 7.572 5.4C7.572 6.6 6.6 7.572 5.4 7.572ZM7.2 20.4H3.6V9.6H7.2V20.4ZM21.6 0H2.4C1.068 0 0 1.068 0 2.4V21.6C0 22.92 1.08 24 2.4 24H21.6C22.92 24 24 22.92 24 21.6V2.4C24 1.068 22.92 0 21.6 0Z"
      fill={fillPrimary}
    />
  );
}

export function SocialFacebook({ fillPrimary }) {
  return (
    <path
      d="M10.781 30V15.313h5l.938-5h-5.938V6.875c0-.938.625-1.719 1.25-1.719h4.687V0h-4.687C8.125 0 5.156 3.438 5.156 7.5v2.813H0v4.844h5.156v14.687h5.625z"
      fill={fillPrimary}
    />
  );
}

export function SocialFacebookSquare({ fillPrimary }) {
  return (
    <path
      fill={fillPrimary}
      d="M16.111,1H2.889A1.894,1.894,0,0,0,1,2.889V16.111A1.894,1.894,0,0,0,2.889,18H9.5V11.389H7.611V9.051H9.5V7.115a3.209,3.209,0,0,1,3.557-3.479l1.7,0V6.1h-1.13a1.248,1.248,0,0,0-1.3,1.358v1.6h2.425l-.536,2.337H12.333V18h3.778A1.894,1.894,0,0,0,18,16.111V2.889A1.894,1.894,0,0,0,16.111,1Z"
      transform="translate(-1 -1)"
    />
  );
}

export function SocialTwitter({ fillPrimary }) {
  return (
    <path
      d="M33.138 7.468c.016.325.022.654.022.979C33.16 18.459 25.542 30 11.611 30A21.422 21.422 0 0 1 0 26.595a15.366 15.366 0 0 0 1.806.106 15.2 15.2 0 0 0 9.406-3.24 7.581 7.581 0 0 1-7.075-5.259 7.741 7.741 0 0 0 1.425.132 7.6 7.6 0 0 0 2-.263 7.582 7.582 0 0 1-6.075-7.43v-.094a7.556 7.556 0 0 0 3.431.949A7.58 7.58 0 0 1 2.57 1.382a21.5 21.5 0 0 0 15.612 7.915 7.65 7.65 0 0 1-.195-1.728 7.578 7.578 0 0 1 13.1-5.181A15.253 15.253 0 0 0 35.9.552a7.6 7.6 0 0 1-3.331 4.189 15.15 15.15 0 0 0 4.35-1.192 15.3 15.3 0 0 1-3.781 3.919z"
      fill={fillPrimary}
    />
  );
}

export function SocialYoutube({ fillPrimary }) {
  return (
    <path
      fill={fillPrimary}
      d="M12.03 2.76C.24 2.76.03 3.81.03 12s.21 9.23 12 9.23 12-1.05 12-9.24-.21-9.23-12-9.23zm3.84 9.64l-5.39 2.51c-.26.17-.6.1-.77-.16a.583.583 0 0 1-.09-.39V9.63c-.03-.3.18-.58.48-.63.13-.02.27.01.38.09l5.39 2.51c.47.22.47.58 0 .8z"
    />
  );
}

export function ArrowSelect({ fillPrimary }) {
  return (
    <path fill={fillPrimary} d="M2.8 9l.7-.7 8.5 8.5 8.5-8.5.7.7-9.2 9.2z" />
  );
}

export function ArrowRight({ fillPrimary }) {
  return (
    <path
      fill={fillPrimary}
      d="M8 7.2l5.3 4.8L8 16.8 9.3 18l6.7-6-6.7-6L8 7.2z"
    />
  );
}

export function ArrowLong({ fillPrimary }) {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="52" height="12" viewBox="0 0 52 12"><path d="M2.07,4,1.025,5.057,5.161,9.25H-44v1.5H5.161L1.025,14.942,2.07,16,8,10Z" transform="translate(44 -4)" fill="#fff" opacity="0.8"/>
    <path
      fill={fillPrimary}
      transform="translate(44 -4)"
      d="M2.07,4,1.025,5.057,5.161,9.25H-44v1.5H5.161L1.025,14.942,2.07,16,8,10Z"
    />
  );
}

export function SplitArrowUp({ fillPrimary }) {
  return (
    <path
      d="M46 26L32 13v38"
      fill="none"
      stroke={fillPrimary}
      strokeWidth="3"
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
  );
}

export function SplitArrowDown({ fillPrimary }) {
  return (
    <path
      d="M18 38l14 13V13"
      fill="none"
      stroke={fillPrimary}
      strokeWidth="3"
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
  );
}

export function Hamburger({ fillPrimary }) {
  return (
    <>
      <path
        fill={fillPrimary}
        d="M-0.1,6.7h17.9v2H-0.1V6.7z M-0.1,0v2h17.9V0H-0.1z M-0.1,15.5h17.9v-2H-0.1V15.5z"
      />
    </>
  );
}

export function Veggyburger({ fillPrimary }) {
  return (
    <>
      <rect fill={fillPrimary} y="8" width="24" height="2" />
      <rect fill={fillPrimary} y="15" x="6" width="18" height="2" />
    </>
  );
}

export function PersonDeliver({ fillPrimary }) {
  return (
    <g fill="transparent">
      <path
        d="M20.501 12.1h9.7v6.8h-9.9v-.5l4.9-.7c.7-.1 1.2-.7 1.3-1.4 0-.7-.5-1.4-1.3-1.6l-4.5.6-.2-3.2z"
        stroke={fillPrimary}
        strokeWidth="1.2"
      />
      <path
        d="M2.701 27.2l6.4-.2 1.8-4.5c.2-.4.3-.9.2-1.3-.2-1-1.2-8.5-1.2-8.5-.1-.9.1-1.7.7-2.4 1.2-1.3 3.1-1.4 4.5-.3 1.4 1.2 4.2 3.9 4.2 3.9v-2.3l1.6-2.5c.3-.4.7-.6 1.2-.6h7.8c1 0 1.4.3 1.6.6.6.9 1.5 2.5 1.5 2.5v8.5s.1 1-.8 1h-12.1s-.7.1-.7-1v-1.8l-2.8-2.5.6 5s-.2.3.3.6 5.1 3.7 5.1 3.7c.4.2.6.5.7.9.2.7 1.3 6.5 1.3 6.5s.4 2.1-1.3 2.4c-1.1.3-2.1-.4-2.4-1.4v-.2c-.1-.6-1-5.2-1-5.2s.1-.2-.5-.7c-.6-.4-4.7-3.5-4.7-3.5l-2.9 6.5-8.9.3c-.9 0-1.7-.8-1.7-1.7-.2-1.6 1.5-1.8 1.5-1.8z"
        stroke={fillPrimary}
        strokeWidth="1.8"
      />
      <g transform="translate(6.911)">
        <circle cx="4" cy="4" r="4" />
        <circle cx="4" cy="4" stroke={fillPrimary} strokeWidth="1.5" r="3.2" />
      </g>
    </g>
  );
}

export function ArrowOr({ fillPrimary }) {
  return (
    <>
      <path
        fill={fillPrimary}
        d="M162.4 35l-2.2 10.5-10.2-3.3-.6 2.6 12.8 4.2 2.8-13.2-2.6-.8z"
      />
      <path
        d="M15 34.4C40-9 128.9-9.8 154.3 34.4"
        fill="none"
        stroke={fillPrimary}
        strokeWidth="3"
        strokeDasharray="10"
      />
      <path
        fill={fillPrimary}
        d="M7.6 35l2.2 10.5L20 42.2l.6 2.6L7.8 49 5 35.8l2.6-.8z"
      />
    </>
  );
}

export function Pin({ fillPrimary }) {
  return (
    <path
      d="M18.261 50h-.012a1.5 1.5 0 01-1.274-.693L2.687 26.821A17.241 17.241 0 010 17.578 17.813 17.813 0 0118 0a17.813 17.813 0 0118 17.578 17.244 17.244 0 01-2.539 9.006l-13.924 22.7a1.516 1.516 0 01-1.276.716zM18 8.725a8.912 8.912 0 00-6.3 15.185 8.884 8.884 0 0015.19-6.281A8.9 8.9 0 0018 8.725zm0 14.86a5.95 5.95 0 01-4.2-10.161A5.943 5.943 0 1118 23.585z"
      fill={fillPrimary}
    />
  );
}

export function Close({ fillPrimary }) {
  return (
    <path
      fill={fillPrimary}
      d="M7.778,9.192,1.414,15.557,0,14.142,6.364,7.778,0,1.414,1.414,0,7.778,6.364,14.142,0l1.415,1.414L9.192,7.778l6.364,6.364-1.415,1.415Z"
    />
  );
}
