import { sx } from '@lucifer/sx';
import styled from '@emotion/styled';

import { media } from '~/utils/styles';

import { Box } from 'theme-ui';
import Section from '~/components/Section';
import WrapComponent from '~/components/Wrap';
import Image from '~/components/Image';

export const BgImage = styled(Image)(
  {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    objectFit: 'cover',
    left: 0,
    top: 0,
    zIndex: -1,
    transition: 'opacity 800ms ease-out',
  },
  props => ({
    opacity: props.active ? 1 : 0,
  }),
);

export const SectionHomeHero = sx(Section)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 2,

  '&.HeroSection': {
    '&:first-of-type': {
      paddingTop: '40px',
    },

    em: {
      textDecoration: 'underline',
    },

    [media('tablet')]: {
      paddingTop: '0',
      paddingBottom: '0',
      em: {
        textDecoration: 'none',
      },
      '&:first-of-type': {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },

    [media('laptop')]: {
      paddingTop: '0',
      paddingBottom: '0',
      '&:first-of-type': {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },

    [media('desktop')]: {
      paddingTop: '0',
      paddingBottom: '0',
      '&:first-of-type': {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
  },
});

export const HoverBlock = styled(Box)(
  {
    margin: '20px 0px',
    color: 'rgba(255,255,255,0.3)',
    cursor: 'pointer',
    transition: 'opacity 800ms ease-out, transform 800ms ease-out',
    userSelect: 'none',

    em: {
      color: 'rgba(255,255,255,1)',
      transition: 'color 800ms ease-in-out',
      fontStyle: 'normal',
    },

    // [HeadlineComponent]: {
    //   color: 'inherit',
    // },

    '*': {
      color: 'inherit !important',
    },
  },
  props => ({
    pointerEvents: props.itemActive ? 'none' : 'all',
    transitionDelay: `${props.delay * 200}ms`,
    opacity: props.fadeIn ? 1 : 0,
    transform: props.fadeIn ? 'translateY(0)' : 'translateY(10px)',

    em: {
      color: props.fadeIn
        ? 'rgba(255,255,255,1) !important'
        : 'rgba(255,255,255,0.3) !important',
      transitionDelay: `${(props.delay + 2) * 500}ms`,
    },
  }),
);

export const Wrap = sx(WrapComponent)({});

export const TagBox = styled(Box)(
  {
    transition: 'opacity 300ms ease-out, transform 300ms ease-out',
    background: '#141414',
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '20px',

    width: 'calc(100vw - 17vw)',
    right: '8.5vw',
    minHeight: '180px',
    padding: '18px 12px',
    bottom: '8.5vw',
    position: 'fixed',
    zIndex: 100,
    pointerEvents: 'none',

    [media('laptop')]: {
      position: 'absolute',
      width: '290px',
      minHeight: '180px',
      right: '8.5vw',
      padding: '18px 12px',
      bottom: 120,
      top: 'auto',
    },

    span: {
      opacity: 0.8,
      display: 'inline-block',
      padding: '5px 6px',
    },
  },
  props => ({
    opacity: props.active ? 1 : 0,
    transform: props.active ? 'translateY(0)' : 'translateY(10px)',
  }),
);

export const ContentWrap = sx(Box)(props => ({
  maxWidth: 800,
}));
