import React from 'react';
import { Flex, Box } from 'theme-ui';
import { useLastSectionTheme } from '~/utils/hooks';

import Wrap from '~/components/Wrap';
import Socials from '~/components/Socials';
import LegalMenu from '~/components/LegalMenu';
import SwitchTheme from '~/components/SwitchTheme';

import { SectionFooter } from './SectionFooter.styled';

export default ({ className, socials }) => {
  const lastSectionThemeName = useLastSectionTheme();
  return (
    <SwitchTheme themeName={lastSectionThemeName}>
      <SectionFooter className={className} element="footer">
        <Wrap>
          <Flex style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <Box
              width={[1, 1, 'auto']}
              style={{ paddingTop: 40, paddingBottom: 40 }}
            >
              <Socials socials={socials} />
            </Box>
            <Box
              width={[1, 1, 'auto']}
              style={{ paddingTop: 40, paddingBottom: 40 }}
            >
              <LegalMenu menuName="navigationFooter" />
            </Box>
          </Flex>
        </Wrap>
      </SectionFooter>
    </SwitchTheme>
  );
};
