import React from 'react';

import { Wrap } from './Wrap.sx';

export default ({ children, className, wrapSize }) => {
  return (
    <Wrap className={className} wrapSize={wrapSize}>
      {children}
    </Wrap>
  );
};
