export const buildImageObj = source => {
  if (!source) return null; // just in case there is no source.

  const imageObj = {
    asset: { _ref: source?.asset?._ref || source?.asset?._id },
    crop: source?.crop,
    hotspot: source?.hotspot,
  };

  return imageObj;
};
