import React from 'react';

// import ReactHtmlComment from '~/utils/ReactHtmlComment';
import SwitchTheme from '~/components/SwitchTheme';

import { DecorationText } from './Decoration.sx';

export default ({ children, className, decoration }) => {
  const type = decoration?.type;
  const themeName = decoration?.themeName;

  switch (type) {
    default:
    case 'none':
      return null;

    // [1] https://stackoverflow.com/questions/40015336/how-to-render-a-html-comment-in-react
    // [2] https://stackoverflow.com/questions/3207211/is-there-a-way-to-make-robots-ignore-certain-text
    case 'text':
      return (
        <SwitchTheme themeName={themeName}>
          <DecorationText className={className}>
            {/* <ReactHtmlComment text="googleoff: index" /> */}
            {children}
            {/* <ReactHtmlComment text="googleon: index" /> */}
          </DecorationText>
        </SwitchTheme>
      );
  }
};
