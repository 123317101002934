import React, { useEffect, useState } from 'react';
import { Flex, Box } from 'theme-ui';

import SwitchTheme from '~/components/SwitchTheme';
import Modules from '~/components/Modules';

import {
  SectionHomeHero,
  Wrap,
  ContentWrap,
  HoverBlock,
  TagBox,
  BgImage,
} from './SectionHomeHero.sx';

export default ({ fullWidth, className, themeName, blocks, section }) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [bgActive, setBgActive] = useState(null);

  useEffect(() => {
    window.setTimeout(() => {
      setFadeIn(true);
    }, 800);
  }, []);

  const show = index => {
    setBgActive(index);
  };

  const hide = () => {
    setBgActive(null);
  };

  return (
    <SwitchTheme themeName={themeName}>
      <SectionHomeHero className={'HeroSection'} section={section}>
        <Wrap wrapSize={section?.wrapSize}>
          <Flex justifyContent="center">
            <Box width={[1, null, null, null, 5 / 8]}>
              <ContentWrap fullWidth={fullWidth}>
                {blocks.map((block, index) => (
                  <HoverBlock
                    key={block._key}
                    onMouseEnter={() => {
                      show(index);
                    }}
                    onMouseLeave={() => {
                      hide();
                    }}
                    fadeIn={fadeIn}
                    delay={index}
                  >
                    <Modules content={block.text} />
                  </HoverBlock>
                ))}
              </ContentWrap>
            </Box>
          </Flex>
        </Wrap>
        {blocks.map((block, index) => (
          <BgImage
            image={block.image}
            active={bgActive === index}
            key={block._key}
          />
        ))}
        {blocks.map((block, index) => (
          <TagBox active={bgActive === index} key={block._key}>
            {block.tags.map((tag, index) => (
              <span key={`${tag}${index}`}>{tag}</span>
            ))}
          </TagBox>
        ))}
      </SectionHomeHero>
    </SwitchTheme>
  );
};
