import React from 'react';

import Social from '~/components/Social';

import { Socials, Title } from './Socials.sx';

export default ({ className, socials }) => {
  const title = socials?.title;
  const socialItems = socials?.socialItemType;

  return (
    <Socials className={className}>
      {title && <Title>{title}</Title>}
      {socialItems.map(({ _key, __typename, socialItem }) => (
        <Social key={_key} type={__typename} url={socialItem.url} />
      ))}
    </Socials>
  );
};
