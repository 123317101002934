import { sx } from '@lucifer/sx';
import { Link as GatsbyLink } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const styles = props => ({
  textDecoration: 'none',
  // padding-top: 0.2em;
  // padding-bottom: 0.2em;
  verticalAlign: 'bottom',
  // color: 'inherit',
  color: 'bright',
  '&:hover': {
    // boxShadow: 'inset 0 -0.1em 0 0 currentColor',
    color: '#ff6600',
  },
  cursor: props.onClick && 'pointer', // polyfill if link has onClick event ~DH
});

export const Link = sx(GatsbyLink)(props => styles(props));

export const LinkOutbound = sx(OutboundLink)(props => styles(props));
