import { useState, useEffect, useCallback } from 'react';
// Hook
const isClient = typeof window === 'object';
function getSize() {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  };
}
export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getSize);
  const handleResize = useCallback(
    function handleResize() {
      setWindowSize(getSize());
    },
    [setWindowSize],
  );
  useEffect(() => {
    if (!isClient) {
      return false;
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]); // Empty array ensures that effect is only run on mount and unmount
  return windowSize;
};
// Usage
// function App() {
//   const size = useWindowSize();
//   return (
//     <div>
//       {size.width}px / {size.height}px
//     </div>
//   );
// }
