import React from 'react';
import { useTheme } from 'emotion-theming';

import { Section, Footer, Header } from './Section.sx';

export default ({ element = 'section', children, className, section }) => {
  let Component;
  let themeType;
  const currentTheme = useTheme();

  switch (element) {
    case 'footer':
      Component = Footer;
      break;
    case 'header':
      Component = Header;
      break;
    case 'section':
    default:
      Component = Section;
      themeType = currentTheme?.type;
      break;
  }

  return (
    <Component
      element={element}
      className={className}
      data-theme-type={themeType}
      section={section}
    >
      {children}
    </Component>
  );
};
