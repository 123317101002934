import { sx } from '@lucifer/sx';

import { media } from '~/utils/styles';

import WrapComponent from '~/components/Wrap';
import LogoComponent from '~/components/Logo';

export const SectionHeader = sx.Header(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: '3',
    height: 72,
    [media('laptop')]: {
      height: 120,
    },
  },
  props =>
    props.isSticky && {
      position: 'fixed',
      background: '#fff',
      boxShadow: '0 0 6px rgba(0,0,0,0.05)',
      height: 72,
      [media('laptop')]: {
        height: 72,
      },
    },
);

export const Wrap = sx(WrapComponent)({
  height: 'inherit',
});

export const FlexBottom = sx.Flex({
  flexWrap: 'nowrap',
  alignItems: 'center',
  height: 'inherit',
});

export const FlexCenter = sx.Flex({
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  lineHeight: 1,
});

export const Box = sx.Box({
  width: '100%',
});

export const Logo = sx(LogoComponent)(
  {
    height: 36,
    transition: '0.1s height ease-in-out',
  },
  props =>
    props.isSticky && {
      height: 36,
    },
);
