import { media } from '~/utils/styles';
import { sx } from '@lucifer/sx';
import styled from '@emotion/styled';

import { Box, Flex } from 'theme-ui';
import Image from '~/components/Image';
import Link from '~/components/Link';

import Section from '~/components/Section';

export const SectionProjects = sx(Section)({});

export const Project = sx(Box)({
  margin: '20px',
  width: '100%',

  [media('tablet')]: {
    width: 'calc(50% - 40px)',
  },

  [media('laptop')]: {
    width: 'calc(33.33% - 40px)',
  },
});

export const Projects = sx(Flex)({
  margin: '0 -20px',
  flexWrap: 'wrap',
  flexDirection: 'row',
  width: 'calc(100% + 40px)',
});

export const CaseImage = styled(Image)({
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  objectPosition: 'center',
  width: '100%',
  height: '100%',
  transition: 'all 200ms ease-out',
  zIndex: 0,
});

export const CaseText = sx(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  transition: 'all 200ms ease-out',
  background: 'rgba(0,0,0,0.5)',
  opacity: 0,
  pointerEvents: 'none',
  zIndex: 1,
  padding: 40,
  color: 'white',
});

export const Content = sx(Box)({
  transition: 'all 300ms ease-in-out',
  transform: 'translateY(10px)',
});

export const CaseLink = sx(Link)({
  display: 'block',
  width: '100%',
  paddingTop: '100%',
  position: 'relative',
  overflow: 'hidden',

  '&:hover': {
    [CaseImage]: {
      transform: 'scale(1.1)',
    },

    [CaseText]: {
      opacity: 1,
    },

    [Content]: {
      transform: 'translateY(0)',
    },
  },
});
