import React from 'react';

import { fractions } from '~/utils/sanity';

import {
  Box,
  TilesModules,
  Content,
  Decoration,
  Icon,
  IconText,
} from './TilesModules.styled';

export default ({ columnWidth, columns, className }) => {
  return columns ? (
    <TilesModules className={className} flexWrap="wrap" mx={-4}>
      {columns?.map(column => (
        <Box key={column?._key} width={fractions(columnWidth)} px={4} pb={6}>
          {column?.hasDecoration && (
            <Decoration>
              <Icon type="arrowOr" />
              <IconText>oder</IconText>
            </Decoration>
          )}
          <Content content={column?.content} />
        </Box>
      ))}
    </TilesModules>
  ) : null;
};
