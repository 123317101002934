import styled from '@emotion/styled';

import { media } from '~/utils/styles';

const getSpacing = (size, spacing) => {
  let calculatedSpacing = spacing;

  switch (size) {
    case 'huge':
      calculatedSpacing = spacing * 4;
      break;
    case 'large':
      calculatedSpacing = spacing * 2;
      break;
    case 'medium':
      calculatedSpacing = spacing;
      break;
    case 'small':
      calculatedSpacing = spacing / 2;
      break;
    case 'mini':
      calculatedSpacing = spacing / 4;
      break;
    case 'none':
      calculatedSpacing = 0;
      break;
    default:
      calculatedSpacing = spacing;
      break;
  }

  return calculatedSpacing;
};

export const Section = styled('section')(
  {
    position: 'relative',
  },
  props => ({
    backgroundImage:
      props.section?.bgImage?.asset &&
      `url(${props.section?.bgImage?.asset?.url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: props.section?.heightIntrinsic ? 'auto' : '100vh',
    zIndex: props.theme.indexPrimary,
    // why these values? they derive from the header and footer height, by default
    // we give them at least their padding to make space for header and footer element.
    // absolute positioned backgroundimages and so forth gonna ignore it anyway.
    // but it is extremly helpful to have one single point of truth regarding
    // section dimensions. I highly recommend to not change this and more try to
    // understand. this you will safe a lot of time. You will find the same "fix"
    // within the "sections.sx.js" component. ~DH
    paddingTop: getSpacing(props.section?.paddingTop, props.theme.spaceTop),
    paddingBottom: getSpacing(
      props.section?.paddingBottom,
      props.theme.spaceBottom,
    ),
    [`${Section}&:first-of-type`]: {
      paddingTop: getSpacing('large', props.theme.spaceTop),
    },
    [media('laptop')]: {
      [`${Section}&:first-of-type`]: {
        paddingTop: getSpacing('large', props.theme.spaceTopLaptop),
      },
      paddingTop: getSpacing(
        props.section?.paddingTop,
        props.theme.spaceTopLaptop,
      ),
      paddingBottom: getSpacing(
        props.section?.paddingBottom,
        props.theme.spaceBottomLaptop,
      ),
    },
  }),
);

export const Header = styled('header')(props => ({
  zIndex: props.theme.indexPrimary,
}));

export const Footer = styled('footer')(props => ({
  zIndex: props.theme.indexPrimary,
}));
