/* eslint-disable no-unused-expressions */

// why is there a getLinks function you may ask. well there are different kind of links with different attributes
// this function here is to normalize them to make one kind of a link out of them
// to understand this compare files from the backend/ folder in the schema called link* with this file here and you
// will understand. its about normalizing the links and check if the most important values are set if not pick the
// next one out of the array and so forth ~DH

export const getLinks = links => {
  let to;
  let blank;

  // here we pick from two possible cases: ~DH
  // 1. a link that derives from a _rawField (e.g. job custom type / blocks)
  // 2. a link that derives from unfolding a regular graphql query (e.g. primaryNavigation menu)
  const sourceType = links?.linkType || [
    { ...(links?.linkStatic || links?.linkReference) },
  ];

  sourceType?.map(link => {
    switch (link?._type) {
      case 'linkStatic':
        to = link?.href;
        blank = link?.blank;
        break;

      case 'linkReference':
      default:
        to =
          link?.reference?.slug?.current || // type: page // this is weird and needs to be debugged ~DH
          link?.reference?.page?.slug?.current; // type: jobs // this is weird and needs to be debugged ~DH
        break;
    }
  });

  if (to) {
    return {
      to,
      blank,
    };
  }

  return {
    to: false,
    blank: false,
  };
};
