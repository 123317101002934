import { sx } from '@lucifer/sx';

import BaseBlockContent from '@sanity/block-content-to-react';

import WrapComponent from '~/components/Wrap';

import { media } from '~/utils/styles';

export const Wrap = sx(WrapComponent)({});

export const Sections = sx(BaseBlockContent)(
  {
    '.p + .p': {
      marginTop: 16,
    },

    '.h1': {
      marginTop: 32,
      marginBottom: 32,
    },

    '.h2': {
      marginTop: 32,
      marginBottom: 24,
    },

    '.h3': {
      marginTop: 32,
      marginBottom: 12,
    },

    '.h4': {
      marginTop: 32,
      marginBottom: 12,
    },

    '.h5': {
      marginTop: 32,
      marginBottom: 12,
    },

    '.h6': {
      marginTop: 32,
      marginBottom: 12,
    },
  },
  props => ({
    // this is important because here we are mixing up single elements/modules with sections and we want to
    // make sure everything has the same "starting-point" in terms of spacings. Difference here is,
    // and thats why the spacings are different, is that these are mostly text and inline elements which
    // are not centered and will mostly start directly after the header ~DH
    [Wrap]: {
      '&:first-of-type': {
        marginTop: 0, // negates module/element top margin to have header correct aligned through every page ~DH
        paddingTop: props.hasHeader && props.theme.spaceTop,
        [media('laptop')]: {
          paddingTop: props.hasHeader && props.theme.spaceTopLaptop,
        },
      },
      '&:last-of-type': {
        marginBottom: 0, // negates module/element bottom margin to have footer correct aligned through every page ~DH
        paddingBottom: props.hasFooter && props.theme.spaceBottom,
        [media('laptop')]: {
          paddingBottom: props.hasHeader && props.theme.spaceBottomLaptop,
        },
      },
    },
  }),
);
