import styled from '@emotion/styled';

import Section from '~/components/Section';

export const SectionFooter = styled(Section)({
  position: 'relative',
  zIndex: 2,
  textTransform: 'uppercase',
  fontSize: 11,
  letterSpacing: 1,
  padding: '32px 0',
  minHeight: 56,
});
