import { useState, useEffect } from 'react';

export const useFirstSectionTheme = () => {
  const [item, setItem] = useState();

  useEffect(() => {
    const items = document.querySelectorAll('section[data-theme-type]');
    setItem(items?.[0]?.dataset?.themeType);
  }, [item]);

  return item;
};
