import styled from '@emotion/styled';
import { sx } from '@lucifer/sx';

import { Flex } from 'theme-ui';
import Link from '~/components/Link';
import Icon from '~/components/Icon';
import LogoComponent from '~/components/Logo';

import { media } from '~/utils/styles';

export const LayoutNav = styled('nav')({
  display: 'flex',
  alignItems: 'center',
});

export const BurgerIcon = styled(Icon)({
  userSelect: 'none',
});

export const CloseIcon = styled(Icon)({
  userSelect: 'none',
});

export const NavItem = sx(Link)({
  fontWeight: 700,
  transition: 'color 0.2s ease-in-out',
  letterSpacing: 0.3,
  whiteSpace: 'nowrap',
  fontSize: 14,
  marginLeft: 50,
  color: 'greySenary',
  fontFamily: 'body',
  '&:hover': {
    color: 'bright',
  },
  '&.active': {
    color: 'bright',
  },
  [media('zero', 'tablet')]: {
    width: '100%',
    display: 'block',
    padding: '30px 0px',
    marginLeft: '8.5vw',
  },
});

export const NavItemBurger = styled('div')({
  [media('zero', 'laptop')]: {
    cursor: 'pointer',
    color: 'white',
    position: 'fixed',
    top: 0,
    right: 0,
    padding: '24px 24px 25px 25px',
    display: 'block',
  },
  [media('laptop')]: {
    display: 'none',
  },
});

export const NavClose = styled('div')({
  cursor: 'pointer',
  color: 'white',
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '24px 24px 25px 25px',
  [media('zero', 'laptop')]: {
    display: 'block',
  },
  [media('laptop')]: {
    display: 'none',
  },
});

export const MobileFooterNav = styled(Flex)({
  paddingLeft: '8.5vw',

  [media('laptop')]: {
    display: 'none',
  },
});

export const MainNav = styled('div')({});

export const OverlayContainer = styled('div')({
  [media('zero', 'tablet')]: {
    height: '100vh',
    width: '100vw',
    overflowX: 'hidden',
    overflowY: 'scroll',
    padding: '100px 0px',
  },
});

export const MobileOverlay = styled('div')(
  {
    [media('zero', 'tablet')]: {
      overflow: 'hidden',
      position: 'fixed',
      zIndex: 1000000,
      background: 'black',
      top: 0,
      left: 0,
      transition: 'all 200ms ease-in-out',
      height: '200vh',
      width: '100vw',
    },
  },
  props => ({
    [media('zero', 'tablet')]: {
      opacity: props.menuOpen ? 1 : 0,
      pointerEvents: props.menuOpen ? 'all' : 'none',
      transform: props.menuOpen ? 'translateX(0)' : 'translateX(-50px)',
    },
  }),
);

export const Logo = sx(LogoComponent)({
  height: 36,
  transition: '0.1s height ease-in-out',
  position: 'absolute',
  top: 19,
  left: '8.5vw',
  [media('zero', 'laptop')]: {
    display: 'block',
  },
  [media('laptop')]: {
    display: 'none',
  },
});
