export default () => null;

// import React from 'react';
// import GoogleMapReact from 'google-map-react';
// import { meters2ScreenPixels } from 'google-map-react/utils';

// import {
//   SectionLocation,
//   PinWrap,
//   Pin,
//   GoogleMapWrap,
//   Circle,
// } from './SectionLocation.styled';

// import { cudino } from './mapTheme';

// const mapOptions = {
//   scrollwheel: false,
//   mapTypeControl: false,
//   scaleControl: false,
//   draggable: false,
//   zoomControl: false,
//   streetViewControl: false,
//   rotateControl: false,
//   fullscreenControl: false,
//   styles: cudino,
// };

// export default ({ className, content, mapLocation }) => {
//   const pinIcon = mapLocation?.pinIcon;
//   const pinCoordinate = mapLocation?.pinCoordinate;
//   const pinRadius = mapLocation?.pinRadius;
//   const lat = pinCoordinate?.lat;
//   const lng = pinCoordinate?.lng;
//   const zoom = 13;

//   // circle calculation
//   // https://github.com/google-map-react/google-map-react/issues/349 ~DH
//   const circleSettings = [
//     pinRadius, // in meter
//     { lat, lng }, // cordinates
//     zoom, // zoom
//   ];
//   const circleWidth = meters2ScreenPixels(...circleSettings).w;
//   const circleHeight = meters2ScreenPixels(...circleSettings).h;
//   return (
//     <SectionLocation className={className}>
//       <GoogleMapWrap>
//         <GoogleMapReact
//           bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_API_KEY }}
//           defaultCenter={{
//             lat,
//             lng,
//           }}
//           defaultZoom={zoom}
//           options={mapOptions}
//         >
//           <Circle circleWidth={circleWidth} circleHeight={circleHeight} />
//           <PinWrap lat={lat} lng={lng}>
//             <Pin type={pinIcon} />
//           </PinWrap>
//         </GoogleMapReact>
//       </GoogleMapWrap>
//     </SectionLocation>
//   );
// };
