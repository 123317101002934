import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { Box as BoxComponent } from 'theme-ui';
import { media } from '~/utils/styles';

export const ImageBox = styled(BoxComponent)(() => ({
  height: '100%',
  div: {
    height: '100%',
  },
}));

export const Image = styled(Img)(
  {
    maxWidth: '100%',
    height: '100%',
    objectFit: 'cover',
    verticalAlign: 'bottom',
  },
  props => ({
    display: props.hasMobile ? 'none' : 'block',
    [media('tablet')]: {
      display: 'block',
    },
  }),
);

export const ImageMobile = styled(Img)(() => ({
  maxWidth: '100%',
  height: '100%',
  objectFit: 'cover',
  verticalAlign: 'bottom',
  display: 'block',
  [media('tablet')]: {
    display: 'none',
  },
}));
