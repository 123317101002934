import React, { useEffect, useState } from 'react';

import Modules from '~/components/Modules';
import useLocalStorage from '~/utils/hooks/useLocalStorage';
import { CookieBox, CookieText, CookieButton } from './CookieConsent.sx';

export default ({ cookieConsent }) => {
  const [cookieConsentStorage, setCookieConsentStorage] = useLocalStorage(
    'cookieConsentStorage',
    false,
  );
  const [cookieConsentState, setCookieConsentState] = useState(
    'cookieConsentState',
    false,
  );

  useEffect(() => {
    window.accepts_cookies = cookieConsentState;
    setCookieConsentStorage(cookieConsentState);
    window.dataLayer.push({ event: 'cookie_click' });
  }, [cookieConsentState]);

  useEffect(() => {
    setCookieConsentState(cookieConsentStorage);
  }, []);

  return (
    <CookieBox cookieConsent={cookieConsentState}>
      <CookieText>
        <Modules content={cookieConsent?._rawCookieConsentText} />
      </CookieText>
      <CookieButton
        variant="ghost"
        onClick={(e) => {
          e.preventDefault();
          setCookieConsentState(true);
        }}
      >
        {cookieConsent?.cookieConsentButtonText}
      </CookieButton>
    </CookieBox>
  );
};
