import { breakpoints } from '~/config/variables';

/**
 * @param {number|string} valueDefault
 * @param {number|string} valueMax
 * @param {string} axis
 * @param {string} orientation
 */
export const media = (
  valueDefault = 0,
  valueMax = 0,
  orientation = null,
  axis = 'width',
) => {
  const baseBreakpointsMin = breakpoints;
  const isValueDefaultNumber = typeof valueDefault === 'number';

  if (baseBreakpointsMin === undefined)
    return console.error('The config object was not found.');

  const baseBreakpointsMax = {};
  const baseBreakpointsMinKeys = Object.keys(baseBreakpointsMin);
  const baseBreakpointsMinValues = Object.values(baseBreakpointsMin);

  baseBreakpointsMinValues.push(baseBreakpointsMinValues.shift());

  baseBreakpointsMinKeys.forEach((key, i) => {
    if (baseBreakpointsMinValues[i] !== 0)
      baseBreakpointsMax[key] = baseBreakpointsMinValues[i] - 1;
    else baseBreakpointsMax[key] = baseBreakpointsMinValues[i];
  });

  if (typeof valueDefault === 'object') {
    let additionalMediaqueryMin = [];

    valueDefault.map(value => {
      additionalMediaqueryMin.push(baseBreakpointsMin[value]);
      return null;
    });

    if (
      additionalMediaqueryMin.length !== valueDefault.length ||
      additionalMediaqueryMin === undefined
    ) {
      console.warn(
        'There is at least one named breakpoint that does not exist!',
      );
    }

    additionalMediaqueryMin = additionalMediaqueryMin.shift();

    valueDefault = additionalMediaqueryMin;

    if (typeof valueMax === 'object') {
      let additionalMediaqueryMax = [];

      valueMax.map(value => {
        additionalMediaqueryMax.push(baseBreakpointsMax[value]);
        return null;
      });

      additionalMediaqueryMax = additionalMediaqueryMax.pop();

      valueMax = additionalMediaqueryMax;
    }
  }

  if (typeof valueMax === 'string') valueMax = baseBreakpointsMax[valueMax];

  if (typeof valueDefault === 'string')
    valueDefault = baseBreakpointsMin[valueDefault];

  if (
    isValueDefaultNumber &&
    typeof valueMax === 'undefined' &&
    valueDefault > valueMax
  ) {
    console.warn('Min value is larger than max value!');
    return '';
  }

  if (valueDefault === 0 && valueMax === 0 && !orientation) {
    console.warn(
      `Min and max values both result in zero, which makes the breakpoint usage obsolete.
      E.g. using breakpoint 'mobile' is useless since this is a mobile first approach.
      So everything is initially visible to mobile devices!`,
    );

    return '';
  }

  const fragments = [];

  if (typeof valueDefault === 'number' && valueDefault !== 0) {
    fragments.push(`(min-${axis}: ${valueDefault}px)`);
  }

  if (typeof valueMax === 'number' && valueMax !== 0) {
    fragments.push(`(max-${axis}: ${valueMax}px)`);
  }

  if (typeof orientation === 'string' && orientation !== '') {
    fragments.push(`(orientation: ${orientation})`);
  }

  if (fragments.length > 0) {
    return `@media ${fragments.join(' and ')}`;
  }
};

// Usage:
//
// ----------------------------------------
//  min-width from predefined breakpoint
// ----------------------------------------
// [media('mobile')]: { ... } => @media (min-width: 480px)
// [media('tablet')]: { ... } => @media (min-width: 768px)
//
// ----------------------------------------
//  min-width from number
// ----------------------------------------
// [media(480)]: { ... } => @media (min-width: 480px)
//
// ----------------------------------------
//  max-width from number
// ----------------------------------------
// [media(480)]: { ... } => @media (min-width: 480px)
// [media(0, 480)]: { ... } => @media (max-width: 480px)
//
// ----------------------------------------
//  min- and max-width from number
// ----------------------------------------
// [media(1, 480)]: { ... } => @media (min-width: 1px) and (max-width: 480px)
//
// ----------------------------------------
//  min- and max-width from number
//  and predefined breakpoint
// ----------------------------------------
// [media(480, 'tablet')]: { ... }  => @media (min-width: 480px) and (max-width: 768px)
// [media('mobile', 'tablet')]: { ... }  => @media (min-width: 480px) and (max-width: 1024px)
