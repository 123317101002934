import { sx } from '@lucifer/sx';
import styled from '@emotion/styled';
import { Flex } from 'theme-ui';
import { media } from '~/utils/styles';

import Section from '~/components/Section';

import Image from '~/components/Image';
import Modules from '~/components/Modules';
import WrapComponent from '~/components/Wrap';

export const Wrap = sx(WrapComponent)({
  alignSelf: 'center',
});

export const SectionTextImage = sx(Section)({
  position: 'relative',
});

export const ImageTextWrap = styled(Flex)((props) => ({
  flexDirection: props.reverseMobile ? 'column-reverse' : 'column',
  alignItems: props.verticalAlign || 'center',
  [media('laptop')]: {
    flexDirection: props.reverse ? 'row-reverse' : 'row',
  },
}));

const getPadding = (wrapSize) => {
  switch (wrapSize) {
    case 'full':
      return '8.5vw';
    case 'small':
      return null;
    case 'none':
      return;
    default:
      return 0;
  }
};

export const ImageTextTileText = styled(Flex)((props) => ({
  maxWidth: '100%',
  flexBasis: '100%',
  alignItems: 'center',
  marginBottom: props.reverseMobile ? '0px' : '60px',
  marginTop: props.reverseMobile ? '60px' : '0px',
  paddingLeft: getPadding(props.wrapSize),
  paddingRight: getPadding(props.wrapSize),

  [media('laptop')]: {
    maxWidth: '50%',
    flexBasis: '50%',
    padding: '0',
    paddingLeft: !props.reverse ? getPadding(props.wrapSize) : 0,
    paddingRight: props.reverse ? getPadding(props.wrapSize) : 0,
  },
}));

export const ImageTextTileImage = sx.Flex({
  maxWidth: '100%',
  flexBasis: '100%',
  alignItems: 'center',
  height: 'auto',
  width: '100%',
  [media('laptop')]: {
    maxWidth: '50%',
    flexBasis: '50%',
  },
});

export const ImageTextTileInner = sx.Box({
  width: '100%',
  maxWidth: '100%',
  paddingLeft: '0',
  paddingRight: '0',
});

export const ImageTextAsset = sx(Image)({
  position: 'relative',
  width: '100%',
  objectFit: 'cover',
  verticalAlign: 'bottom',
});

export const ImageTextContent = styled(Modules)((props) => ({
  h2: {
    marginBottom: 24,
  },
  h1: {
    marginBottom: 32,
  },
  h5: {
    marginBottom: 18,
  },
  p: {
    marginBottom: 18,

    '&+h2, &+h3, &+h1': {
      marginTop: 70,
    },
  },
  [media('laptop')]: {
    paddingLeft: props.reverse ? '60px' : '0px',
    paddingRight: props.reverse ? '0' : '60px',
  },
}));
