import React from 'react';

import { H1, H2, H3, H4, H5, H6 } from './Headline.sx';

export default ({ element, children, className }) => {
  let Headline;

  switch (element) {
    case 'h1':
      Headline = H1;
      break;
    case 'h2':
      Headline = H2;
      break;
    case 'h3':
    default:
      Headline = H3;
      break;
    case 'h4':
      Headline = H4;
      break;
    case 'h5':
      Headline = H5;
      break;
    case 'h6':
      Headline = H6;
      break;
  }

  return <Headline className={className}>{children}</Headline>;
};
