import { sx } from '@lucifer/sx';
import { media } from '~/utils/styles';

export const Socials = sx.Box({
  marginTop: 32,
  '& > *': {
    marginRight: 48,
    '&:last-child': {
      marginRight: 0,
    },
  },
  [media('tablet')]: {
    marginTop: 0,
    '& > *': {
      marginRight: 52,
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
});

export const Title = sx('span')({
  userSelect: 'none',
  color: 'bright',
});
