import React from 'react';
import { Flipper } from 'react-flip-toolkit';
import { Global } from '@emotion/core';

import SectionHeader from '~/components/SectionHeader';
import Seo from '~/components/Seo';
import SectionFooter from '~/components/SectionFooter';
import CookieConsent from '~/components/CookieConsent';

import {
  Layout,
  styles,
  openSans300woff2,
  openSans400woff2,
  openSans700woff2,
} from './Layout.sx.js';

export default ({ children, cookieConsent, hasHeader, hasFooter, seo, socials }) => {
  return (
    <>
      <Global styles={styles} />
      <Seo seo={seo} socials={socials}>
        <link
          rel="preload"
          href={openSans300woff2}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={openSans400woff2}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href={openSans700woff2}
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=no"
        />
      </Seo>
      <Flipper
        flipKey="test"
        staggerConfig={{
          // the "default" config will apply to staggered elements without explicit keys
          default: {
            // default direction is forwards
            reverse: true,
            // default is .1, 0 < n < 1
            speed: 1,
          },
          // this will apply to Flipped elements with the prop stagger='namedStagger'
          namedStagger: { speed: 0.2 },
        }}
        spring={{ stiffness: 280, damping: 22 }}
      >
        <Layout>
          {hasHeader && <SectionHeader socials={socials} />}
          {children}
          {hasFooter && <SectionFooter socials={socials} />}
          <CookieConsent cookieConsent={cookieConsent} />
        </Layout>

      </Flipper>
    </>
  );
};
