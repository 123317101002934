import React from 'react';

import { useScrollPosition, useFirstSectionTheme } from '~/utils/hooks';

import LayoutNav from '~/components/LayoutNav';
import SwitchTheme from '~/components/SwitchTheme';

import {
  SectionHeader,
  Wrap,
  Logo,
  FlexBottom,
  FlexCenter,
  Box,
} from './SectionHeader.sx';

export default ({ socials }) => {
  const firstSectionThemeName = useFirstSectionTheme();
  const scrollPosition = useScrollPosition();
  const isSticky = scrollPosition > 24;

  return (
    <SwitchTheme themeName={isSticky ? 'bright' : firstSectionThemeName}>
      <SectionHeader isSticky={isSticky}>
        <Wrap>
          <FlexBottom>
            <Box width="100%">
              <FlexCenter>
                <Logo isSticky={isSticky} />
                <LayoutNav menuName="navigationPrimary" socials={socials} />
              </FlexCenter>
            </Box>
          </FlexBottom>
        </Wrap>
      </SectionHeader>
    </SwitchTheme>
  );
};
