import { css } from '@emotion/core';
import { sx } from '@lucifer/sx';
import { colors, fonts } from '~/config/variables';

import OpenSans300ItalicWOFF2 from '~/assets/fonts/open-sans-v17-latin-italic.woff2';
import OpenSans300ItalicWOFF from '~/assets/fonts/open-sans-v17-latin-italic.woff';
import OpenSans300ItalicTTF from '~/assets/fonts/open-sans-v17-latin-italic.ttf';

import OpenSans400WOFF2 from '~/assets/fonts/open-sans-v17-latin-regular.woff2';
import OpenSans400WOFF from '~/assets/fonts/open-sans-v17-latin-regular.woff';
import OpenSans400TTF from '~/assets/fonts/open-sans-v17-latin-regular.ttf';

import OpenSans700WOFF2 from '~/assets/fonts/open-sans-v17-latin-700.woff2';
import OpenSans700WOFF from '~/assets/fonts/open-sans-v17-latin-700.woff';
import OpenSans700TTF from '~/assets/fonts/open-sans-v17-latin-700.ttf';

export const openSans300woff2 = OpenSans300ItalicWOFF2;
export const openSans400woff2 = OpenSans400WOFF2;
export const openSans700woff2 = OpenSans700WOFF2;

// local import since no way to specify font-display on @font-face
// https://github.com/typekit/webfontloader/issues/409
// old embed via typekit @import url('https://use.typekit.net/${process.env.GATSBY_TYPEKIT_ID}.css'); ~DH

export const styles = css`
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    font-display: 'swap';
    src: url(${OpenSans300ItalicWOFF2}) format('woff2'),
      url(${OpenSans300ItalicWOFF}) format('woff'),
      url(${OpenSans300ItalicTTF}) format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: 'swap';
    src: url(${OpenSans400WOFF2}) format('woff2'),
      url(${OpenSans400WOFF}) format('woff'),
      url(${OpenSans400TTF}) format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: bold;
    font-weight: 700;
    font-display: 'swap';
    src: url(${OpenSans700WOFF2}) format('woff2'),
      url(${OpenSans700WOFF}) format('woff'),
      url(${OpenSans700TTF}) format('truetype');
  }

  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline-color: ${colors.greenSecondary};
  }

  html {
    color: ${colors.greyPrimary};
    background-color: #141414;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.875;
    box-sizing: border-box;
    overflow-y: scroll;
  }

  body {
    font-family: ${fonts.fontPrimary};
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }
`;

export const Layout = sx.Box({
  position: 'relative',
  backgroundColor: '#141414',
  zIndex: '1',
});
