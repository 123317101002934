import { sx } from '@lucifer/sx';

import { media } from '~/utils/styles';

import Link from '~/components/Link';

export const Logo = sx(Link)({
  display: 'inline-block',
  marginLeft: -16,

  [media('laptop')]: {
    marginLeft: -25 * 1.2,
    minWidth: 25 * 1.2,
    minHeight: 38 * 1.2,
  },
});

export const Slate = sx('path')({
  fill: 'orangeSecondary',
});

export const LogoImageText = sx('svg')({
  height: 'inherit',
  color: 'bright',
  '&:hover': {
    color: 'bright',
  },
});
