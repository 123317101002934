import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { getMenuNodeByName, getLinks } from '~/utils/sanity';

import { LegalMenu, MenuItem } from './LegalMenu.sx';

export default ({ className, menuName = '' }) => {
  const data = useStaticQuery(graphql`
    {
      allSanityMenu {
        edges {
          node {
            name
            menuItem {
              menuItemType {
                __typename
                ... on SanityMenuItemStatic {
                  _key
                  title
                  linkStatic {
                    _key
                    _type
                    href
                    blank
                  }
                }
                ... on SanityMenuItemReference {
                  _key
                  title
                  linkReference {
                    _type
                    reference {
                      ... on SanityProject {
                        page {
                          slug {
                            current
                          }
                        }
                      }
                      ... on SanityPage {
                        slug {
                          current
                        }
                      }
                      ... on SanityAsset {
                        file {
                          asset {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const menu = getMenuNodeByName(data, menuName);
  const menuItems = menu?.menuItem?.menuItemType;

  return menuItems ? (
    <LegalMenu className={className}>
      {menuItems?.map(menuItem => {
        return <MenuItem
          key={menuItem?._key}
          activeClassName="active"
          to={getLinks(menuItem)?.to || menuItem.linkReference?.reference?.file?.asset?.url || null}
          target={getLinks(menuItem)?.blank || menuItem.linkReference?.reference?.file ? '_blank' : null}
        >
          {menuItem?.title}
        </MenuItem>
      })}
    </LegalMenu>
  ) : null;
};
