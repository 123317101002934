import { buildImageObj as buildImageObjSanity } from './buildImageObj';
import { cn as cnSanity } from './cn';
import { filterOutDocsWithoutSlugs as filterOutDocsWithoutSlugsSanity } from './filterOutDocsWithoutSlugs';
import { getBlogUrl as getBlogUrlSanity } from './getBlogUrl';
import { imageUrlFor as imageUrlForSanity } from './imageUrlFor';
import { mapEdgesToNodes as mapEdgesToNodesSanity } from './mapEdgesToNodes';
import { getMenuNodeByName as getMenuNodeByNameSanity } from './getMenuNodeByName';
import { getLinks as getLinksSanity } from './getLinks';
import { fractions as fractionsSanity } from './fractions';
import { stripAfterChar as stripAfterCharSanity } from './stripAfterChar';

export const buildImageObj = buildImageObjSanity;
export const cn = cnSanity;
export const filterOutDocsWithoutSlugs = filterOutDocsWithoutSlugsSanity;
export const getBlogUrl = getBlogUrlSanity;
export const imageUrlFor = imageUrlForSanity;
export const mapEdgesToNodes = mapEdgesToNodesSanity;
export const getMenuNodeByName = getMenuNodeByNameSanity;
export const getLinks = getLinksSanity;
export const fractions = fractionsSanity;
export const stripAfterChar = stripAfterCharSanity;
