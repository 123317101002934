import React from 'react';
import { useTheme } from 'emotion-theming';

import SwitchTheme from '~/components/SwitchTheme';
import Icon from '~/components/Icon';

import { Button, Input, Link, ButtonIcon, ButtonMetaTitle } from './Button.sx';

export default ({
  children,
  type,
  value,
  className,
  to,
  target,
  disabled,
  form,
  formaction,
  formenctype,
  formmethod,
  formnovalidate,
  formtarget,
  name,
  rel,
  onClick,
  blank,
  themeName,
  ariaLabel,
  metaTitle,
  variant = 'solid', // solid, ghost, stripped
}) => {
  const currentTheme = useTheme();
  switch (type) {
    case 'button':
    case 'reset':
    case 'submit':
      return (
        <SwitchTheme themeName={themeName || currentTheme?.type}>
          <Button
            to={to}
            className={className}
            disabled={disabled}
            form={form}
            formaction={formaction}
            formenctype={formenctype}
            formmethod={formmethod}
            formnovalidate={formnovalidate}
            formtarget={formtarget}
            name={name}
            type={type}
            value={value}
            variant={variant}
            ariaLabel={ariaLabel}
          >
            {metaTitle && <ButtonMetaTitle>{metaTitle}</ButtonMetaTitle>}
            {children}
            {variant === 'circle' && <ButtonIcon><Icon type="arrowRightLong" /></ButtonIcon>}
          </Button>
        </SwitchTheme>
      );
    case 'input':
      if (children) {
        console.warn(
          'An input field (type button) can not have any children.',
          children,
        );
      }
      return (
        <SwitchTheme themeName={themeName || currentTheme?.type}>
          <Input
            className={className}
            disabled={disabled}
            form={form}
            formaction={formaction}
            formenctype={formenctype}
            formmethod={formmethod}
            formnovalidate={formnovalidate}
            formtarget={formtarget}
            name={name}
            type="button"
            defaultValue={value}
            variant={variant}
            ariaLabel={ariaLabel}
          />
        </SwitchTheme>
      );

    case 'link':
    default:
      return (
        <SwitchTheme themeName={themeName || currentTheme?.type}>
          <Link
            to={to}
            blank={blank}
            className={className}
            disabled={disabled}
            target={target}
            rel={rel}
            onClick={onClick}
            variant={variant}
            ariaLabel={ariaLabel}
          >
            {metaTitle && <ButtonMetaTitle>{metaTitle}</ButtonMetaTitle>}
            {children}
            {variant === 'circle' && <ButtonIcon><Icon type="arrowRightLong" /></ButtonIcon>}
          </Link>
        </SwitchTheme>
      );
  }
};
