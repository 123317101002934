import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { getMenuNodeByName, getLinks } from '~/utils/sanity';

import { Box } from 'theme-ui';
import Socials from '~/components/Socials';

import {
  LayoutNav,
  NavItem,
  NavItemBurger,
  BurgerIcon,
  CloseIcon,
  NavClose,
  MobileOverlay,
  OverlayContainer,
  MainNav,
  Logo,
  MobileFooterNav,
} from './LayoutNav.styled';

export default ({ className, menuName = '', socials }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const data = useStaticQuery(graphql`
    {
      allSanityMenu {
        edges {
          node {
            name
            menuItem {
              menuItemType {
                __typename
                ... on SanityMenuItemStatic {
                  _key
                  title
                  linkStatic {
                    _key
                    _type
                    href
                    blank
                  }
                }
                ... on SanityMenuItemReference {
                  _key
                  title
                  linkReference {
                    _type
                    reference {
                      ... on SanityProject {
                        page {
                          slug {
                            current
                          }
                        }
                      }
                      ... on SanityPage {
                        slug {
                          current
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const menu = getMenuNodeByName(data, menuName);
  const menuItems = menu?.menuItem?.menuItemType;

  return menuItems.length > 0 ? (
    <LayoutNav className={className}>
      <MobileOverlay menuOpen={menuOpen}>
        <OverlayContainer>
          <MainNav>
            {menuItems?.map(menuItem => {
              return (
                <NavItem
                  key={menuItem?._key}
                  to={getLinks(menuItem)?.to}
                  target={getLinks(menuItem)?.blank ? '_blank' : null}
                >
                  {menuItem?.title}
                </NavItem>
              );
            })}
          </MainNav>
          <MobileFooterNav
            style={{
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              paddingRight: 40,
            }}
          >
            <Box
              width={[1, 1, 'auto']}
              style={{ paddingTop: 20, paddingBottom: 20 }}
            >
              <Socials socials={socials} />
            </Box>
          </MobileFooterNav>
          <Logo />
          <NavClose
            role="button"
            aria-label="Hamburger Menü Toggle"
            onClick={() => setMenuOpen(false)}
          >
            <CloseIcon type="close" />
          </NavClose>
        </OverlayContainer>
      </MobileOverlay>
      <NavItemBurger
        role="button"
        aria-label="Hamburger Menü Toggle"
        onClick={() => setMenuOpen(true)}
      >
        <BurgerIcon type="hamburger" />
      </NavItemBurger>
    </LayoutNav>
  ) : null;
};
