import { sx } from '@lucifer/sx';

import Section from '~/components/Section';
import ReactPlayer from 'react-player';
import { Box } from 'theme-ui';

export const SectionVideo = sx(Section)({
  display: 'flex',
  minHeight: 'auto',
});

export const Player = sx(ReactPlayer)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100% !important',
  height: '100% !important',
});

export const PlayerSizer = sx(Box)({
  paddingTop: `${(9 / 16) * 100}%`,
  position: 'relative',
});
