import React from 'react';
import { Flipped } from 'react-flip-toolkit';

import Wrap from '~/components/Wrap';
import SwitchTheme from '~/components/SwitchTheme';
import Headline from '~/components/Headline';
import Modules from '~/components/Modules';

import {
  SectionProjects,
  Projects,
  Project,
  CaseImage,
  CaseLink,
  CaseText,
  Content,
} from './SectionProjects.styled';

export default ({ className, projects, section }) => {
  return (
    <SwitchTheme>
      <SectionProjects className={className} section={section}>
        <Wrap>
          <Projects>
            {projects
              ? projects.map((project) => {
                  if (!project || project?.page?.hidden === true) {
                    return null;
                  } else {
                    return (
                      <Project key={project?._id}>
                        <Flipped flipId="projectHero">
                          <CaseLink to={project?.page?.slug?.current}>
                            <CaseText>
                              <Content>
                                <Headline element="h3">
                                  {project?.projectInfo?.projectTitle}
                                </Headline>
                                <Modules
                                  content={project?.projectInfo?.salutation}
                                />
                              </Content>
                            </CaseText>
                            <CaseImage
                              image={project?.projectInfo?.teaserImage}
                            />
                          </CaseLink>
                        </Flipped>
                      </Project>
                    );
                  }
                })
              : null}
          </Projects>
        </Wrap>
      </SectionProjects>
    </SwitchTheme>
  );
};
