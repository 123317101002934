import React from 'react';

import SwitchTheme from '~/components/SwitchTheme';
import Wrap from '~/components/Wrap';
import { SectionVideo, Player, PlayerSizer } from './SectionVideo.sx';

export default ({ className, themeName, video, section }) => {
  return (
    <SwitchTheme themeName={themeName}>
      <SectionVideo
        className={className}
        section={{
          ...section,
          paddingBottom: section?.paddingBottom || 'none',
          paddingTop: section?.paddingTop || 'none',
        }}
      >
        <Wrap wrapSize={section?.wrapSize}>
          <PlayerSizer>
            <Player url={video} controls />
          </PlayerSizer>
        </Wrap>
      </SectionVideo>
    </SwitchTheme>
  );
};
