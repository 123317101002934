import React from 'react';
import { Flex, Box } from 'theme-ui';

import SwitchTheme from '~/components/SwitchTheme';

import { SectionText, Wrap, Content, Decoration, ContentWrap } from './SectionText.sx';

export default ({ content, fullWidth, className, themeName, decoration, section }) => {
  return (
    <SwitchTheme themeName={themeName}>
      <SectionText className={className} section={section}>
        <Wrap wrapSize={section?.wrapSize}>
          <Flex justifyContent="center">
            <Box width={[1, null, null, null, 5 / 8]}>
              <Decoration decoration={decoration} />
              <ContentWrap fullWidth={fullWidth}>
                <Content content={content} />
              </ContentWrap>
            </Box>
          </Flex>
        </Wrap>
      </SectionText>
    </SwitchTheme>
  );
};
