import { sx } from '@lucifer/sx';

import { Flex, Box } from 'theme-ui';
import Button from '~/components/Button';

export const CookieBox = sx(Flex) (
  (props) => ({
    position: 'fixed',
    bottom: 0,
    right: 0,
    margin: 20,
    padding: 20,
    background: 'black',
    alignItems: 'center',
    zIndex: 1000,
    color: 'greySenary',
    transition: 'all 200ms ease-out',
    opacity: props.cookieConsent ? 0 : 1,
    pointerEvents: props.cookieConsent ? 'none' : 'all',
    transform: props.cookieConsent ? 'translateX(50%)' : 'translateX(0px)'
  })
);

export const CookieText = sx(Box)({
  marginRight: 30
});

export const CookieButton = sx(Button)({
  cursor: 'pointer',
});