import { sx } from '@lucifer/sx';
import { media } from '~/utils/styles';

export const DecorationText = sx('span')({
  position: 'absolute',
  left: '-5.4vw',
  fontSize: '30vw',
  top: 0,
  color: 'greySeptenary',
  zIndex: -1,
  transform: 'translateY(-50%)',
  fontWeight: 'bold',
  userSelect: 'none',
  whiteSpace: 'nowrap',

  [media('tablet')]: {
    left: '-5.4vw',
    fontSize: '10vw',
  },
});
